import React, { Component } from "react";
import { Fade, Flip } from "react-reveal";

class Contact extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const mailto = this.props.data.mailto;

    return (
      <section id="about">
         <div className="row">
            <Fade bottom duration={1200}>
              <h2>Let's Chat!</h2>
            </Fade>
            <Fade bottom duration={2000}>
              <ul className="social">
              <Flip bottom delay={1500}>
                <a href={mailto} className="button email-btn">
                  <i className="fa fa-envelope"></i> Send me an email  
                </a>
              </Flip>
              </ul>
            </Fade>
        </div>
      </section>
    );
  }
}

export default Contact;
