import React, { Component } from "react";
import {Fade, Flip} from "react-reveal";
import { TypeAnimation } from "react-type-animation";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const profilepic = "images/" + this.props.data.image;
    const bio = this.props.data.bio;
    const phone = this.props.data.phone;
    const email = this.props.data.email;
    const resume = this.props.data.resume;

    return (
      <section id="about">
        <Fade duration={1000}>
          <div className="row">
            <div className="three columns">
              <img
                className="profile-pic"
                src={profilepic}
                alt="Ziyue Zhang Profile Photo"
              />
            </div>
            <div className="nine columns main-col">
              <TypeAnimation
                sequence={["", 200, "You can call me JJ!", 1000]}
                speed={40} 
                wrapper="h2" 
                repeat={0}/>
              <p>{bio}</p>
              <div className="row">
                <div className="columns contact-details">
                  <h3>Get in Touch</h3>
                  <p className="address">
                    <span>Phone: {phone}</span>
                    <br />
                    <span>Email: {email}</span>
                  </p>
                </div>
                <div className="columns download">
                  <p>
                    <Flip bottom delay={500}>
                      <a href={resume} className="button">
                        <i className="fa fa-download"></i>Download Resume
                      </a>
                    </Flip>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;
