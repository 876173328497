import React, { Component } from "react";
import Zmage from "react-zmage";
import Fade from "react-reveal";
import { TypeAnimation } from 'react-type-animation';

let id = 0;
class Portfolio extends Component {
  render() {
    if (!this.props.data) return null;

    const projects = this.props.data.projects.map(function (projects) {
      let projectImage = "images/portfolio/" + projects.image;
      if (projects.url == "") {
        return (
          <div key={id++} className="columns portfolio-item">
            <div className="item-wrap">
              <Zmage alt={projects.title} src={projectImage} />
              <div className="no-link">
                <div style={{ textAlign: "center" }}>{projects.title}</div>
              </div>
            </div>
          </div>
        );
      }
      else {
      return (
        <div key={id++} className="columns portfolio-item">
          <div className="item-wrap">
            <Zmage alt={projects.title} src={projectImage} />
            <a href={projects.url}>
              <div style={{ textAlign: "center" }}>{projects.title}</div>
            </a>
          </div>
        </div>
      );
      }
    });

    return (
      <section id="portfolio">
        <TypeAnimation
                sequence={[
                "Check out some of my Passion Projects",
                1000,
                "Check out some of my Hackathon Projects",
                1000,
                "Check out some of my University Projects",
                1000,
                "Check out some of my Freelance Projects",
                1000,
                ]}
                speed={40} 
                wrapper="h1" 
                repeat={Infinity}/>
        <Fade bottom duration={1000} distance="40px">
          <div className="row">
            <div className="twelve columns collapsed">
              <div
                id="portfolio-wrapper"
                className="bgrid-quarters s-bgrid-thirds cf"
              >
                {projects}
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default Portfolio;
