import React, { Component } from "react";
import ParticlesBg from "particles-bg";
import { Zoom, Flip } from "react-reveal";
import { TypeAnimation } from 'react-type-animation';

class Header extends Component {
  render() {
    if (!this.props.data) return null;

    const resume = this.props.data.resume;
    const github = this.props.data.github;
    const project = this.props.data.project;
    const name = this.props.data.name;
    const description1 = this.props.data.description1;
    const description2 = this.props.data.description2;

    return (
      <header id="home">
        {/*<ParticlesBg type="lines" bg={true} />*/}

        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#home">
                Home
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#about">
                About
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#resume">
                Resume
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#portfolio">
                Side Projects
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#contact">
                Contact
              </a>
            </li>
          </ul>
        </nav>

        <div className="row banner">
          <div className="banner-text">
              <TypeAnimation
                sequence={["", 1800, "Hello, I'm Ziyue.", 1000]}
                speed={40} 
                wrapper="h1" 
                repeat={0}/>
              <TypeAnimation
                  sequence={[
                  "",
                  3000,
                  "I'm a Product Manager",
                  1000,
                  "I'm a Fullstack Developer",
                  1000,
                  "I'm a ReactJS Enthusiast",
                  1000,
                  "I'm a Data Fanatic",
                  1000,
                  "I'm a Builder",
                  1000,
                  ]}
                  speed={40} 
                  style={{ fontSize: '2em' }}
                  wrapper="h3" 
                  repeat={Infinity} 
                />
              <ul className="social">
                <Flip bottom delay={4000}>
                  <a href={project} className="button btn project-btn">
                    <i className="fa fa-book"></i>PM Work Samples
                  </a>
                </Flip>
                <Flip bottom delay={4000}>
                  <a href={github} className="button btn github-btn">
                    <i className="fa fa-github"></i>Github
                  </a>
                </Flip>
              </ul>
          </div>
        </div>

        {/*<p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
    </p>*/}
      </header>
    );
  }
}

export default Header;
