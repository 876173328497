import React, { Component } from "react";
import ReactGA from "react-ga";
import $ from "jquery";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Resume from "./Components/Resume";
import Contact from "./Components/Contact";
import Portfolio from "./Components/Portfolio";
import Anime from "react-anime";
import { Sugar } from 'react-preloaders2';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foo: "bar",
      resumeData: {}
    };

    ReactGA.initialize("UA-110570651-1");
    ReactGA.pageview(window.location.pathname);
  }

  getResumeData() {
    $.ajax({
      url: "./resumeData.json",
      dataType: "json",
      cache: false,
      success: function(data) {
        this.setState({ resumeData: data });
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount() {
    this.getResumeData();
  }

  render() {
    return (
      <div>
       <Sugar className="preloader" time={1300} color="#ffffff"
          background="linear-gradient(180deg, #934ec8 0%, #220b35 100%)"/>
        <div className="body">
          <div className="wave-container-header">
            <ul class="background">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
            <Header data={this.state.resumeData.main} />
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 260"><path fill="#220b35" fill-opacity="1" d="M0,128L34.3,138.7C68.6,149,137,171,206,170.7C274.3,171,343,149,411,138.7C480,128,549,128,617,144C685.7,160,754,192,823,213.3C891.4,235,960,245,1029,250.7C1097.1,256,1166,256,1234,245.3C1302.9,235,1371,213,1406,202.7L1440,192L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path></svg>
            </div>
          <div className="wave-container-about">
            <About data={this.state.resumeData.main} />
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#934ec8" fill-opacity="1" d="M0,96L34.3,122.7C68.6,149,137,203,206,213.3C274.3,224,343,192,411,160C480,128,549,96,617,96C685.7,96,754,128,823,122.7C891.4,117,960,75,1029,48C1097.1,21,1166,11,1234,26.7C1302.9,43,1371,85,1406,106.7L1440,128L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path></svg> 
          </div>
          <div className="wave-container-resume">
            <Resume data={this.state.resumeData.resume} />
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#934ec8" fill-opacity="1" d="M0,96L34.3,128C68.6,160,137,224,206,261.3C274.3,299,343,309,411,266.7C480,224,549,128,617,80C685.7,32,754,32,823,37.3C891.4,43,960,53,1029,69.3C1097.1,85,1166,107,1234,101.3C1302.9,96,1371,64,1406,48L1440,32L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"></path></svg>
          </div>
          <Portfolio data={this.state.resumeData.portfolio} />
          <div className="wave-container-contact">
          <dl class="background-spin">
            <dt></dt>
            <dt></dt>
            <dt></dt>
            <dt></dt>
            <dt></dt>
            <dt></dt>
            <dt></dt>
            <dt></dt>
            <dt></dt>
            <dt></dt>
            <dt></dt>
            <dt></dt>
            <dt></dt>
            <dt></dt>
            <dt></dt>
            <dt></dt>
            <dt></dt>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#220b35" fill-opacity="1" d="M0,160L34.3,165.3C68.6,171,137,181,206,154.7C274.3,128,343,64,411,80C480,96,549,192,617,234.7C685.7,277,754,267,823,234.7C891.4,203,960,149,1029,149.3C1097.1,149,1166,203,1234,192C1302.9,181,1371,107,1406,69.3L1440,32L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"></path></svg>
            <Contact data={this.state.resumeData.main} />
            <Footer data={this.state.resumeData.main} />
            </dl>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
